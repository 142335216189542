@import "overrides";
@import "bulma/utilities/_all";
@import "bulma/base/_all";
@import "bulma/elements/_all";
@import "bulma/form/_all";
@import "bulma/components/_all";
@import "bulma/grid/_all";
@import "bulma/layout/_all";

h1 {
	font-weight: 600;
	font-size: 24px;
}

h2 {
	font-size: 20px;
	font-weight: 400;
	padding-top: 20px;
}

.screen-item {
    display: inline-block;
}

h1 {
	padding-top: 20px;
	padding-bottom: 8px;
}

h1.post-title {
	padding-top: 0px;
	padding-bottom: 8px;
}

#press a {
	color: #2281EE;
}

#press h2 {
	padding-bottom: 5px;
}

#press ul {
	list-style-type: circle;
}

#press #unlimited-price {
	padding-bottom: 5px;
	font-weight: bold;
}

#press td {
	padding: 2px;
}