@charset 'UTF-8';

@import "main";

html {
    scroll-behavior: smooth;
}

.screenshot {
    position: relative;
    width: 325px;
    margin-left: auto;
    margin-right: auto;
  }

  .device-overlay {
      position: relative;
    width: 325px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
  }

.screen-pic {
    position: absolute;
  width: 308px;
  height: auto;
  z-index: 0;
  padding-top: 14px;
  padding-left: 17px;
  background-color:transparent;
}

#promo-video {
    display: none;
    width: 288px;
    padding-top: 34px;
    padding-left: 37px;
}

#screens-container {
    display: block;
    padding-top: 40px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

#screens-container-mobile {
    margin-left: -8px;
}